import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'elias-delete-node',
    templateUrl: './delete-node.component.html',
})
export class DeleteNodeComponent {
    constructor(private dialogRef: MatDialogRef<DeleteNodeComponent>) {}

    public onDialogSubmitted(): void {
        this.dialogRef.close(true);
    }
}
