import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'elias-shared-dialog-incoming-maintenance',
    templateUrl: 'incoming-maintenance.component.html',
})
export class IncomingMaintenanceComponent {
    constructor(public dialogRef: MatDialogRef<IncomingMaintenanceComponent>) {}

    public onDialogSubmitted(): void {
        this.dialogRef.close(true);
    }
}
