<div [formGroup]="formGroup">
    <div
        class="view-config-container"
        fxLayout="row"
        fxLayoutGap="30px"
        fxLayoutAlign="space-evenly space-between"
        *ngIf="ObjectKeys(settings).length && settings.filter">
        <div class="filters" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="center center">
            <div class="search-field">
                <input
                    data-cy="select-publication-group-search"
                    type="search"
                    required
                    class="search-input"
                    #filterName
                    placeholder="{{ 'publicationlist.filter.search' | translate }}"
                    (keyup)="onSearch($event.target.value)" />
                <i class="ei ei-close search-cancel" (click)="onResetSearch()"></i>
            </div>
            <elias-shared-filter-dropdown
                class="filter-dropdown"
                title="{{ 'publication.filter.language' | translate }}"
                [options]="settings.languageOptions"
                (changeValue)="onOptionSelected('locale', $event)"></elias-shared-filter-dropdown>
            <elias-shared-filter-dropdown
                class="filter-dropdown"
                title="{{ 'publication.filter.state' | translate }}"
                [options]="settings.stateOptions"
                (changeValue)="onChangeFilterValue('state', $event)"></elias-shared-filter-dropdown>
            <elias-shared-dropdown-multiple
                class="filter-dropdown"
                title="{{ 'publication.filter.category' | translate }}"
                [options]="settings.categoryOptions.genCategoryOptions"
                (changeValue)="onChangeGenCategoryValue('genCategory', $event)"></elias-shared-dropdown-multiple>
            <elias-shared-dropdown-multiple
                class="filter-dropdown"
                title="{{ 'publication.filter.pubType' | translate }}"
                [options]="settings.categoryOptions.pubTypeCategoryOptions"
                (changeValue)="
                    onChangePubTypeCategoryValue('pubTypeCategory', $event)
                "></elias-shared-dropdown-multiple>
            <elias-shared-sorting-dropdown
                class="sorting-dropdown"
                title="{{ 'publication.sort.title' | translate }}"
                [options]="settings.sortOptions"
                [selectedValue]="publicationGroupSorting.key"
                (changeValue)="onChangeSortingValue($event)"></elias-shared-sorting-dropdown>
        </div>
        <div fxFlex="75px" fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-between space-between">
            <elias-shared-view-option-toggle
                [viewOption]="publicationViewOption"
                (toggleViewEvent)="onChangeViewOption($event)"></elias-shared-view-option-toggle>
        </div>
    </div>

    <div fxLayout="column wrap" fxLayoutAlign="center center">
        <div
            eliasInfiniteScrollingContainer
            (lastItemReached)="loadMore()"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
            [ngClass]="{ 'list-width': publicationViewOption === 2 }">
            <ng-container *ngIf="filteredOptions.length > 0; else showNoRecord">
                <ng-container *ngIf="publicationViewOption === 0">
                    <div *ngFor="let option of filteredOptions; trackBy: trackByIndex; let i = index">
                        <mat-card
                            eliasInfiniteScrollingItem
                            class="layout"
                            [ngClass]="{ active: isActive(option), selected: isActive(option) }"
                            (click)="toggleActivation(option)">
                            <div class="mat-card-header" fxLayout="column">
                                <i *ngIf="isActive(option)" class="ei ei-done done-icon"></i>
                            </div>
                            <mat-card-title>{{ option.value | strlen : 36 }}</mat-card-title>
                            <mat-card-content>
                                <div class="tags" fxLayout="row wrap" fxLayoutGap="6px" *ngIf="settings.pills">
                                    <div
                                        class="tag dots"
                                        *ngFor="
                                            let category of ObjectKeys(option.others.categories);
                                            trackBy: trackByIndex
                                        "
                                        [ngStyle]="{
                                            'background-color': categoryQuery.getEntity(category).color
                                        }"
                                        matTooltip="{{ categoryQuery.getEntity(category).name }}"></div>
                                </div>
                            </mat-card-content>
                            <mat-card-footer>
                                <div class="tile-footer">
                                    <div fxLayout="column">
                                        <span class="locale">{{ option.others.locale | locale }}</span>
                                    </div>
                                </div>
                            </mat-card-footer>
                        </mat-card>
                    </div>
                </ng-container>

                <div *ngIf="publicationViewOption === 2" class="publication-list">
                    <mat-list>
                        <ng-container *ngFor="let option of filteredOptions; trackBy: trackByIndex; let i = index">
                            <mat-list-item
                                eliasInfiniteScrollingItem
                                data-cy="select-publication-group-list-item"
                                class="list-view"
                                [ngClass]="{ active: isActive(option), selected: isActive(option) }"
                                (click)="toggleActivation(option)">
                                <div class="column column-title">
                                    <h4 class="title">{{ option.others.name }}</h4>
                                    <h5 class="subtitle">{{ option.others.subtitle }}</h5>
                                    <h6 class="description">
                                        {{ option.others.description | strlen : 70 }}
                                    </h6>
                                </div>
                                <div class="column column-language">
                                    <span>{{ option.others.locale | locale }}</span>
                                </div>
                                <div class="column column-content">
                                    <div class="tags" fxLayout="row wrap" fxLayoutGap="6px">
                                        <div
                                            class="tag dots"
                                            *ngFor="
                                                let category of ObjectKeys(option.others.categories);
                                                trackBy: trackByIndex
                                            "
                                            [ngStyle]="{
                                                'background-color': categoryQuery.getEntity(category).color
                                            }"
                                            matTooltip="{{ categoryQuery.getEntity(category).name }}"></div>
                                    </div>
                                </div>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>
                    </mat-list>
                </div>
            </ng-container>
        </div>

        <elias-shared-loading dotSize="medium" *ngIf="loading"></elias-shared-loading>
    </div>

    <ng-template #showNoRecord>
        <elias-shared-placeholder
            *ngIf="!loading"
            [message]="'publicationlist.noRecordError' | translate"></elias-shared-placeholder>
    </ng-template>
</div>
