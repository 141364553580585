import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'elias-delete-node-subitem',
    templateUrl: './delete-node-subitem.component.html',
})
export class DeleteNodeSubitemComponent {
    constructor(private dialogRef: MatDialogRef<DeleteNodeSubitemComponent>) {}

    public onDialogSubmitted(): void {
        this.dialogRef.close(true);
    }
}
