import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from '../../../shared/services/users.service';

@Component({
    selector: 'elias-data-processing-approval',
    templateUrl: './data-processing-approval.component.html',
})
export class DataProcessingApprovalComponent implements OnInit {
    private userId: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DataProcessingApprovalComponent>,
        private usersService: UsersService
    ) {}

    ngOnInit(): void {
        this.userId = this.data.userId;
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }

    public onConfirm(): void {
        this.usersService.updateUserDataProcessingApproval(this.userId).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
